import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { ScrollView, Text, View } from 'tamagui';
import {
  Dimensions,
  Linking,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import type { DebugInfo } from '../interfaces/DebugInfo.interface';
import { RenderHTML } from 'react-native-render-html';

export const Debug: React.FC<{
  debug: DebugInfo;
  backgroundColor?: string;
  onClose: () => void;
  style?: object;
}> = ({ debug, onClose, backgroundColor, style }) => {
  const styles = StyleSheet.create({
    debugContainer: {
      borderRadius: 16,
      elevation: 5, // For Android
      boxShadow: '1px 1px 8px rgba(0, 0, 0, 0.16)',
      padding: 32,
      flex: 1,
      minWidth: 320,
      backgroundColor: backgroundColor,
    },
    closeButton: {
      position: 'absolute',
      top: 8,
      right: 8,
      backgroundColor: 'lightgrey',
      borderRadius: 16,
      padding: 8,
      zIndex: 1, // Make sure the button is above other elements
    },
    closeButtonText: {
      fontWeight: 'bold',
      color: 'black',
    },
  });
  const screenHeight = Dimensions.get('window').height;

  return (
    <View style={[styles.debugContainer, { height: screenHeight }, style]}>
      <TouchableOpacity
        style={styles.closeButton}
        onPress={() => {
          if (onClose) {
            onClose();
          }
        }}
      >
        <FontAwesomeIcon icon={faX} />
      </TouchableOpacity>
      {debug && (
        <ScrollView>
          {debug.sections &&
            debug.sections.map((section, index) => {
              return (
                <View key={index} style={{ marginBottom: 16 }}>
                  <>
                    {/* <Text>
                      {section.position + 1}) {section.title}
                    </Text> */}
                    <Text
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                        fontSize: '2rem'
                      }}
                    >
                      {section.document_version_title}
                    </Text>

                    <Text
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                      }}
                    >
                      {section.section_title}
                    </Text>

                    <RenderHTML
                      source={{ html: section.section_html_content }}
                    />
                  </>
                </View>
              );
            })}

          <View style={{ marginBottom: 16 }}></View>

          {debug.images &&
            debug.images.map((image, index) => {
              return (
                <View key={index} style={{ marginBottom: 8 }}>
                  <Text
                    onPress={() => {
                      Linking.openURL(image.url);
                    }}
                  >
                    {image.label}
                  </Text>
                </View>
              );
            })}
        </ScrollView>
      )}
    </View>
  );
};

import { useState, useCallback, useRef } from 'react';
import type { DebugInfo } from '../interfaces/DebugInfo.interface';

interface WebSocketMessage {
  type: 'message_content' | 'user_stt_content' | 'images' | 'error' | 'debug';
  images?: { url: string; label: string }[];
  content?: string;
  createdAt: Date;
  complete?: boolean;
  error?: boolean;
  isUser: boolean;
  debug?: DebugInfo;
  // attachments?: Array<{
  //   url: string;
  //   label: string;
  //   type: 'image';
  // }>;
}

type WebSocketCallback = (message: WebSocketMessage) => void;

interface webSocketProps {
  updateAnonymousSession: (sessionId: string) => Promise<void>;
}
export const useWebSocket = ({ updateAnonymousSession }: webSocketProps) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [modelIds, setModelIds] = useState<string[]>([]);
  const [firmwareIds, setFirmwareIds] = useState<string[]>([]);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const MAX_DELAY = 60000; // 1 minute

  const _connectWebSocket = (data: {
    delay?: number;
    wsUrl: string;
    authToken?: string;
    anonymousSessionId?: string;
    host?: string;
    modelIds?: string[];
    firmwareIds?: string[];
  }) => {
    const {
      delay = 1000,
      wsUrl,
      authToken,
      anonymousSessionId,
      host: h,
    } = data;
    if (!wsUrl) return;
    setModelIds(data.modelIds || []);
    setFirmwareIds(data.firmwareIds || []);

    const url = new URL(wsUrl);
    if (authToken) {
      url.searchParams.append('token', authToken);
    } else {
      if (anonymousSessionId) {
        url.searchParams.append('account_id', anonymousSessionId);
      }
      if (h) {
        url.searchParams.append('service_domain', h);
      } else {
        throw Error('In anonymous mode host is required');
      }
    }

    const newSocket = new WebSocket(url.toString());
    setSocket(newSocket);

    newSocket.onopen = () => {
      console.log('WebSocket connected');
      setIsConnected(true);
    };

    newSocket.onclose = () => {
      console.log('WebSocket connection closed');
      setIsConnected(false);
      if (reconnectTimeoutRef.current !== null) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      reconnectTimeoutRef.current = setTimeout(() => {
        _connectWebSocket({ ...data, delay: Math.min(delay * 2, MAX_DELAY) });
      }, delay);
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (reconnectTimeoutRef.current !== null) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      newSocket.close();
    };
  };

  const connectWebSocket = useCallback(
    ({
      wsUrl,
      host,
      authToken,
      anonymousSessionId,
      modelIds,
      firmwareIds,
    }: {
      wsUrl: string;
      host?: string;
      authToken?: string;
      anonymousSessionId?: string;
      modelIds?: string[];
      firmwareIds?: string[];
    }) => {
      if (!isConnected) {
        _connectWebSocket({ wsUrl, host, authToken, anonymousSessionId, modelIds, firmwareIds });
      }
    },
    []
  );

  const sendMessage = useCallback(
    (text: string, newId: string) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const data = { content: text, id: newId } as any;
        if (modelIds.length > 0 && firmwareIds.length > 0) {
          data.query_metadata = {
            model_ids: modelIds,
            firmware_ids: firmwareIds,
          }
        }
        socket.send(JSON.stringify(data));
      } else {
        console.error('WebSocket is not connected. Unable to send message.');
      }
    },
    [socket]
  );

  const onNewMessage = useCallback(
    (callback: WebSocketCallback) => {
      if (socket) {
        socket.onmessage = (event: WebSocketMessageEvent) => {
          const data = JSON.parse(event.data);
          switch (data.event) {
            case 'public_connection_upgrade':
              updateAnonymousSession(data.session_id).catch((e: Error) => {
                console.error('Failed to save session ID:', e);
              });
              break;
            case 'user_stt_content':
              callback({
                content: data.content,
                type: 'message_content',
                createdAt: new Date(),
                isUser: true,
                complete: true,
              });
              break;
            case 'message_content':
              callback({
                content: data.content,
                type: 'message_content',
                createdAt: new Date(),
                complete: data.is_complete,
                isUser: false,
              });
              if (data.is_complete) {
                console.log('Message is complete');
              }
              break;
            case 'images':
              callback({
                images: data.images,
                type: 'images',
                createdAt: new Date(),
                complete: data.is_complete,
                isUser: false,
              });
              break;
            case 'error':
              callback({
                content: `❌ ${data.ws_message}`,
                type: 'error',
                createdAt: new Date(),
                error: true,
                isUser: false,
                complete: true,
              });
              console.error('Error from server:', data.ws_message);
              break;
            case 'debug':
              callback({
                content: '',
                type: 'debug',
                createdAt: new Date(),
                complete: false,
                isUser: false,
                debug: data.debug,
              });
              break;
            default:
              console.log('Received unknown event:', data);
          }
        };
      }
    },
    [socket]
  );

  return { sendMessage, onNewMessage, isConnected, connectWebSocket };
};
